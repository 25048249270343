import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

import rem from '../../utils/rem'
import { navbarHeight, borderRadius } from '../../utils/sizes'
import { lightGrey } from '../../utils/colours'

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin-right: ${rem(-10)};
`

const SocialLink = styled.a`
  display: inline-block;
  margin: 0;
  line-height: ${rem(navbarHeight - 20)};
  color: currentColor;
  transition: opacity 0.2s, transform 0.2s;
  transform: translateZ(0px);
  cursor: pointer;
  &:hover {
    & > div {
      background-color: ${lightGrey};
    }
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.8;
  }
  & > div {
    border-radius: ${rem(borderRadius)};
    margin: ${rem(10)} 0;
    padding: 0 ${rem(10)};
  }
  svg {
    path {
      fill: currentColor;
    }
  }
`

const SocialSeparator = styled.div`
  display: inline-block;
  width: ${rem(5)};
`

export default () => (
  <Wrapper>
    <SocialLink href="https://www.facebook.com/BspServicesLimited/">
      <div>
        <FontAwesomeIcon icon={faFacebook} />
      </div>
    </SocialLink>
  </Wrapper>
)
