import styled, { css } from 'styled-components'
import { darken } from 'polished'

import rem from '../utils/rem'
import { mobile } from '../utils/media'
import { globalPadding, borderRadius } from '../utils/sizes'
import { main, white, paleGrey, dropShadow } from '../utils/colours'

export const Content = styled.div`
  ${'' /* background-color: rgba(102, 51, 153, .2); */}
  width: ${rem(1024)};
  max-width: 100%;
  margin: 0 auto;
  padding: ${rem(globalPadding)};
  box-sizing: border-box;
  transition: transform 150ms ease-out;
  ${mobile(css`
    padding: calc(${rem(globalPadding)});
  `)}
  & > :first-child {
    margin-top: 0;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const Button = styled.a`
  display: inline-block;
  box-sizing: border-box;
  font-size: ${rem(11)};
  font-weight: 500;
  letter-spacing: 0.025rem;
  text-decoration: none;
  text-transform: uppercase;
  color: ${white};
  background-color: ${main};
  border: ${rem(1)} solid ${main};
  border-radius: ${rem(borderRadius)};
  margin-bottom: 1.45rem;
  padding: ${rem(7.5)} ${rem(10)};
  box-shadow: 0 7px 12px -12px ${dropShadow};
  transition: transform 0.2s, background-color .2s, drop-shadow .2s;
  cursor: pointer;
  &:hover {
    background-color: ${darken(.05, main)};
    box-shadow: 0 7px 12px -8px ${dropShadow};
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.8;
  }
`

export const Quiet = styled.p`
  color: currentColor;
  opacity: .6;
`

export const Hr = styled.hr`
  border: 0;
  height: 1px;
  background: none;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0));
`

export const Form = styled.form`
  width: 100%;
`

export const Label = styled.label`
  flex: 1;
  text-align: left;
  margin: 0 0 1.45rem 0;
  padding: 5px;
  font-weight: 500;

  & p {
    padding-left: .5rem;
    margin-bottom: .5rem;
  }
`

export const Input = styled.input`
  border-radius: ${rem(borderRadius)};
  border: ${rem(1)} solid ${paleGrey};
  width: 100%;
  padding: .5rem;

  &.has-error {
    border: ${rem(1)} solid ${main};
  }
`

export const TextArea = styled.textarea`
  border-radius: ${rem(borderRadius)};
  border: ${rem(1)} solid ${paleGrey};
  padding: .5rem;
  width: 100%;

  &.has-error {
    border: ${rem(1)} solid ${main};
  }
`

export const Submit = styled.button`
  margin: 0 auto;
  padding: ${rem(7.5)} ${rem(10)};
  border: none;
  cursor: pointer;
  color: ${white};
  background-color: ${main};
  border: solid 1px transparent;
  border-radius: ${rem(borderRadius)};
  font-size: ${rem(11)};
  font-weight: 500;
  letter-spacing: 0.025rem;
  text-transform: uppercase;

  &:focus:not(.focus-visible) {
    outline: none;
  }

  &:hover {
    background-color: ${darken(.05, main)};
    box-shadow: 0 7px 12px -8px ${dropShadow};
  }
  &:active {
    transform: scale(0.95);
    opacity: 0.8;
  }

  &:disabled, &[disabled] {
    background-color: ${main};
    cursor: not-allowed;
  }
`
