import React from 'react'
import styled from 'styled-components'

import rem from '../utils/rem'
import { paleGrey, grey } from '../utils/colours'
import { FlexContainer } from './Scaffold'

const Wrapper = styled.div`
  min-height: ${rem(100)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: ${paleGrey};
  color: ${grey};
  & p {
    margin: 0;
  }
`

const FooterLink = styled.a`
  flex-shrink: 0;
  color: currentColor;
  text-decoration: underline;
  transition: opacity: 0.2s;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`

export default () => (
  <Wrapper>
    <FlexContainer>
      <p>
        &copy; BSP Services Limited
      </p>
      <p>
        New website is on the way...
      </p>
    </FlexContainer>
  </Wrapper>
)
