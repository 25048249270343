import React, { PureComponent } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import rem from '../../utils/rem'
import { navbarHeight, navbarMaxWidth, globalMargin, globalPadding } from '../../utils/sizes'
import { mobile } from '../../utils/media'
import { main, white, dropShadow } from '../../utils/colours'
import NavLinks from './NavLinks'
import Social from './Social'
import NavSeparator from './NavSeparator'
import MobileNavbar from './MobileNavbar'

const Wrapper = styled.nav`
  position: fixed;
  left: 0;
  box-sizing: border-box;
  z-index: 3;
  width: 100%;
  height: ${rem(navbarHeight)};
  font-size: ${rem(11)};
  font-weight: 500;
  text-transform: uppercase;
  background: ${white};
  transition: background 300ms ease-out;
  color: ${main};
  box-shadow: ${dropShadow} 0px 1px 1px 0px;
  & a {
    text-decoration: none;
  }
`

const NormalNavbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${rem(globalPadding)};
  max-width: ${rem(navbarMaxWidth)};
  margin: 0 auto;
  ${mobile(css`
    display: none;
  `)}
`

const StartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const EndWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const LogoLink = styled(Link).attrs({
    to: '/',
    'aria-label': 'home',
  })`
  display: inline-block;
  vertical-align: center;
  margin-right: ${rem(globalMargin * 2)};
  color: currentColor;
  width: ${rem(navbarHeight)};
`

class NavBar extends PureComponent {
  render() {
    const {
      onMobileNavToggle,
      isMobileNavFolded,
    } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            logo: imageSharp(fixed: {originalName: {regex: "/bsp-logo.png/" }}) {
              sizes(
                quality: 100
                traceSVG: { color: "#ed1c24", threshold: 75 }
                toFormat: PNG
              ) {
                tracedSVG
                src
              }
            }
          }
        `}
        render={data => (
          <Wrapper>
            <NormalNavbar>
              <StartWrapper>
                <LogoLink>
                  <div style={{
                    width: 50,
                    height: 50,
                    backgroundImage:
                      `url("${data.logo.sizes.src}"),
                       url("${data.logo.sizes.tracedSVG}")`,
                    backgroundPosition: 'center center',
                    backgroundSize: 'contain'
                  }} />
                </LogoLink>
              </StartWrapper>
              <EndWrapper>
                <NavLinks />
                <NavSeparator />
                <Social />
              </EndWrapper>
            </NormalNavbar>

            <MobileNavbar
              isMobileNavFolded={isMobileNavFolded}
              onMobileNavToggle={onMobileNavToggle}
            />
          </Wrapper>
        )}
      />
    )
  }
}

export default NavBar
