import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import rem from '../../utils/rem'
import { navbarHeight } from '../../utils/sizes'
import { mobile } from '../../utils/media'
import { main, paleGrey, grey } from '../../utils/colours'
import NavLinks from './NavLinks'
import Social from './Social'
import NavSeparator from './NavSeparator'
import NavButton from './NavButton'

const Wrapper = styled.div`
  display: none;
  color: ${main};

  ${mobile(css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${rem(navbarHeight)};
  `)}
`

const SecondaryMenu = styled.div.attrs({
  'aria-hidden': props => props.open ? 'false' : 'true'
})`
  position: absolute;
  top: ${rem(navbarHeight)};
  left: 0;
  right: 0;
  ${p => p.open ? css`
    height: ${rem(navbarHeight)};
  ` : css`
    height: 0;
  `}
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  transition: height 0.1s;
  user-select: none;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  background: ${paleGrey};
  color: ${grey};
`

const LogoLink = styled(Link).attrs({
  to: '/',
  'aria-label': 'home',
})`
  display: inline-block;
  vertical-align: center;
  margin-left: 1.25rem;
  color: currentColor;
  width: ${rem(50)};
`

const IconWrapper = styled.div`
  transition: transform 0.1s;
  color: currentColor;
  ${p => p.shouldRotate && css`
    transform: rotate(180deg);
  `}
`

const SecondaryMenuItem = styled.div`
  // padding-right: 1.25rem;
`

const MobileNavbar = props => {
  const {
    isMobileNavFolded,
    onMobileNavToggle,
  } = props

  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "bsp-logo.png" }) {
            childImageSharp {
              fluid (maxWidth: 800, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
      <Wrapper>
        <LogoLink>
          <Img fluid={data.logo.childImageSharp.fluid} />
        </LogoLink>

        <Wrapper>
          <NavButton
            onClick={onMobileNavToggle}
            active={!isMobileNavFolded}
          >
            <IconWrapper shouldRotate={!isMobileNavFolded}>
              <FontAwesomeIcon icon={faChevronDown} />
            </IconWrapper>
          </NavButton>
        </Wrapper>

        <SecondaryMenu open={!isMobileNavFolded}>
          <NavLinks />
          <NavSeparator />
          <SecondaryMenuItem>
            <Social />
          </SecondaryMenuItem>
        </SecondaryMenu>
      </Wrapper>
    )} />
  )
}

export default MobileNavbar
