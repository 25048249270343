import styled, { css } from 'styled-components'

import rem from '../../utils/rem'
import { mobile } from '../../utils/media'

const NavSeparator = styled.span`
  flex: 0 0 auto;
  width: 0.125rem;
  height: 0.125rem;
  margin: 0 ${rem(10)};
  ${mobile(css`
    margin: 0 ${rem(5)};
  `)}
  border-radius: 50%;
  background: currentColor;
  opacity: 0.35;
`

export default NavSeparator
