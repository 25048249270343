import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import darken from 'polished/lib/color/darken'

import rem from '../../utils/rem'
import { navbarHeight, borderRadius } from '../../utils/sizes'
import { main, white, lightGrey } from '../../utils/colours'

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`

const NavLink = styled(Link)`
  flex: 0 0 auto;
  display: inline-block;
  margin: 0 ${p => p.alternative ? '.5rem' : '.25rem'};
  line-height: ${rem(navbarHeight)};
  transition: opacity 0.2s, transform 0.2s;
  cursor: pointer;
  letter-spacing: 0.025rem;
  color: ${p => p.alternative ? white : 'currentColor'};
  &:first-of-type {
    margin-left: ${rem(-10)};
  }
  &:hover{
    & p {
      background-color: ${p => p.alternative ? darken(.05, main) : lightGrey};
    }
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.8;
  }
  & p {
    box-sizing: border-box;
    margin: ${rem(10)} 0;
    padding: 0 ${rem(10)};
    line-height: ${rem(navbarHeight - 20)};
    border-radius: ${rem(borderRadius)};
    background-color: ${p => p.alternative ? main : 'rgba(0, 0, 0, 0)'};
    transition: background-color 0.15s;
  }
`

const NavLinks = () =>
  <Wrapper>
    <NavLink to="contact" alternative="true">
      <p>Get in Touch</p>
    </NavLink>
  </Wrapper>

export default NavLinks
