import React from 'react'

import Layout from '../components/Layout'
import { Content, Quiet, Button, Hr } from '../components/Scaffold'

export default () => (
  <Layout>
    <Content>
      <div style={{textAlign: 'center', padding: '28px 0', maxWidth: '600px', margin: '0 auto'}}>
        <h1>Contact Us</h1>
        <p style={{fontSize: 22, fontWeight: 500, marginBottom: '0.5rem'}}>01706 350 716</p>
        <Quiet>Mon - Fri 8:00 AM - 5:00 PM</Quiet>
        <Button href="tel:01706350716">Call Us</Button>
      </div>

      <Hr />

      <div style={{padding: '28px 0', maxWidth: '600px', margin: '0 auto', textAlign: 'center'}}>
        <h1>Thank you!</h1>
        <p>Your message has been sent. We will respond as soon as possible.</p>
      </div>
    </Content>
  </Layout>
)
