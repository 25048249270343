import React, { PureComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled,  { injectGlobal } from 'styled-components'
import { Helmet } from 'react-helmet'
import 'typeface-ibm-plex-sans'

import rem from '../utils/rem'
import { navbarHeight } from '../utils/sizes'
import { white, selection } from '../utils/colours'
import Nav from './Nav'
import Footer from './Footer'

injectGlobal`
  html {
    -webkit-font-smoothing: antialiased;
  }
  *::selection {
    background-color: ${selection};
    color: ${white};
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const PageContainer = styled.div`
  flex: 1 0 auto;
  margin-top: ${rem(navbarHeight)};
`

class Layout extends PureComponent {
  state = {
    isMobileNavFolded: true,
  }

  toggleMobileNav = () => {
    this.setState(prevState => ({
      isMobileNavFolded: !prevState.isMobileNavFolded,
    }))
  }

  onRouteChange = () => {
    this.setState({
      isMobileNavFolded: true,
    })
  }

  render() {
    const { isMobileNavFolded } = this.state
    return (
      <StaticQuery
        query={graphql`
        query {
          site {
            siteMetadata{
              title
              description
            }
          }
        }
      `}
        render={data => (
          <div>
            <Helmet>
              <title>{data.site.siteMetadata.title}</title>
              <meta name="description" content={data.site.siteMetadata.description} />
              <meta name="google-site-verification" content="pvkWykBQlzFwgOZaeeYCuS7d0HcQlS7wI3L2-wNoRjU" />
            </Helmet>
            <Wrapper>
              <Nav
                isMobileNavFolded={isMobileNavFolded}
                onMobileNavToggle={this.toggleMobileNav}
              />
              <PageContainer>
                {this.props.children}
              </PageContainer>
              <Footer />
            </Wrapper>
          </div>
        )}
      />
    )
  }
}

export default Layout
