import darken from 'polished/lib/color/darken'

export const main = '#ed1c24'
export const selection = 'rgba(237, 28, 36, 0.85)'
export const dropShadow = 'rgba(116, 129, 141, 0.09)'

export const white = '#ffffff'

export const paleGrey = '#efefef'
export const grey = darken(0.3, paleGrey)
export const darkGrey = darken(0.6, paleGrey)
export const lightGrey = 'rgba(211, 211, 211, 0.2)'
